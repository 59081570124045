<template lang="html">
  <div id="project-detail-v2-page">

    <section class="placed-backg banners-1-section">
      <swiper class="swiper" :options="bannersOption">
        <swiper-slide v-for="(banner, bp1Inx) in project.banners1" :key="'bp1Inx-'+bp1Inx">
          <a class="d-block placed-backg swiper-img" v-bind:style="{ backgroundImage: 'url('+banner.url+')' }">
            <img src="public/images/pages/projects/banner.png">
          </a>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <div class="box-info">
        <div class="inside">
          <h4 class="title" v-if="$i18n.locale == 'en'">{{ project.name }}</h4>
          <h4 class="title" v-if="$i18n.locale == 'es'">{{ project.name_es }}</h4>
          <a :href="project.documentUrl" target="_blank" class="btn btn-s1 blue btn-bg btn-lg">{{ $t("projects.detail.download_brochure") }}</a>
        </div>
      </div>
    </section>

    <section class="container info1-section">
      <h2 class="title" v-if="$i18n.locale == 'en'">{{ project.name }}</h2>
      <h2 class="title" v-if="$i18n.locale == 'es'">{{ project.name_es }}</h2>

      <div class="box-descr" v-html="project.description" v-if="$i18n.locale == 'en'"> </div> 
      <div class="box-descr" v-html="project.description_es" v-if="$i18n.locale == 'es'"> </div> 
        <!-- <p>With the most spectacular views of the Chapultepec Forest, this unique project of only 23 apartments of exceptional taste and quality arises. It has a swimming pool, roof garden, gym, playroom, garden with children's games, jacuzzi, sundeck, sky lounge, fire pit and driver's room.</p> -->
      
    </section>

    <section class="placed-backg banners-2-section">
      <swiper class="swiper" :options="banners2Option">
        <swiper-slide v-for="(b, bp2Inx) in project.banners2" :key="'bp2Inx-'+bp2Inx">
          <div class="d-block placed-backg swiper-img" v-bind:style="{ backgroundImage: 'url('+b.url+')' }">
          </div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="container masterplan-section">
      <div class="row">
        <div class="col-12 col-top">
          <h2 class="_title-1">{{ $t("projects.detail.masterplan") }}</h2>
        </div>

        <div class="col-lg-6 col-image">
          <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+ project.imagedetailUrl +')' }">
          </div>
        </div>

        <div class="col-lg-6 col-info">
          <!-- <h4 class="subtitle">Visión del proyecto</h4> -->

          <div v-if="$i18n.locale == 'en'" v-html="project.masterplan"></div>
          <div v-if="$i18n.locale == 'es'" v-html="project.masterplan_es"></div>
        </div>
      </div>
    </section>

    <section class="gallery-section">
      <div class="container main-container">
        <div class="row">
          <div class="col-12 col-top">
            <h2 class="_title-1">{{ $t("projects.detail.gallery") }}</h2>
          </div>

          <div class="col-12 col-gallery">
            <swiper class="swiper" :options="galleryOptions">
              <swiper-slide v-for="(g, pgInx) in project.images" :key="'pgInx-'+pgInx">
                <div class="box-image" @click="index = pgInx">
                  <img class="t-150 placed-backg" src="public/images/shared/empty.png" v-bind:style="{ backgroundImage: 'url('+g.url+')' }">
                </div>
              </swiper-slide>

              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>

      <CoolLightBox
        :items="gallery"
        :index="index"
        :effect="'fade'"
        @close="index = null">
      </CoolLightBox>
    </section>

    <section class="container location-section">
      <div class="d-block text-center col-top">
        <h2 class="_title-1">{{ $t("projects.detail.location") }}</h2>
      </div>

      <div v-if="$i18n.locale == 'en'" class="box-descr" v-html="project.location"></div>
      <div v-if="$i18n.locale == 'es'" class="box-descr" v-html="project.location_es"></div>

      <div class="box-map">

        <GmapMap
              :center="{lat:project.lat, lng:project.lng}"
              :zoom="15" v-if="showMap"
              style="width: 100%;;height: 450px;">
              <GmapMarker
                :position="{lat:project.lat, lng:project.lng}"
                :clickable="true"
                :draggable="true"
              />
        </GmapMap>
      </div>

    </section>

    <section class="container contact-section">
      <div class="box-form">
        <b-form class="row justify-content-center" @submit="onSubmit">
          <div class="col-12 text-center col-top">
            <h2 class="_title-1">{{ $t("projects.detail.contact_us") }}</h2>
          </div>

          <div class="col-lg-7">
            <div class="row">

              <div class="col-12 mb-2">
                <b-form-group class="custom-f-group-2"
                  :label='$t("projects.form.name")'>
                  <b-form-input
                    v-model="form.name"
                    type="text"
                    size="lg"
                    placeholder=""
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-12 mb-2">
                <b-form-group class="custom-f-group-2"
                  :label='$t("projects.form.email")'>
                  <b-form-input
                    v-model="form.email"
                    type="email"
                    size="lg"
                    placeholder=""
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-12 mb-2">
                <b-form-group class="custom-f-group-2"
                  :label='$t("projects.form.phone")'>
                  <b-form-input
                    v-model="form.phone"
                    type="number"
                    size="lg"
                    placeholder=""
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-12 mb-2" v-if="$i18n.locale == 'en'">
                <b-form-group class="custom-f-group-2"
                  :label='$t("projects.form.wheredid")'>
                  <b-form-select v-model="form.hear" size="lg" :options="hOptions"></b-form-select>
                </b-form-group>
              </div>
              <div class="col-12 mb-2" v-if="$i18n.locale == 'es'">
                <b-form-group class="custom-f-group-2"
                  :label='$t("projects.form.wheredid")'>
                  <b-form-select v-model="form.hear" size="lg" :options="hOptionsEs"></b-form-select>
                </b-form-group>
              </div>

              <div class="col-sm-7 mb-0 mb-sm-2">
                <b-form-group class="custom-f-group-2"
                  :label='$t("projects.form.bestdate")'>
                  <b-form-datepicker size="lg" v-model="form.bestDate" :min="minDate" :date-disabled-fn="dateDisabled" :date-format-options="dateFormOpts" placeholder="" required></b-form-datepicker>
                </b-form-group>
              </div>

              <div class="col-6 col-sm-5 mb-2">
                <b-form-group class="custom-f-group-2"
                  :label='$t("projects.form.horario")'>
                  <b-form-select v-model="form.bestHour" size="lg" :options="hoursOptions"></b-form-select>
                </b-form-group>
              </div>

              <div class="col-sm-7 mt-2 mt-sm-0 mb-0 mb-sm-2">
                <b-form-group class="custom-f-group-2"
                :label='$t("projects.form.arlternativedate")'>
                  <b-form-datepicker size="lg" v-model="form.alterativeDate" :min="minDate" :date-disabled-fn="dateDisabled" :date-format-options="dateFormOpts" placeholder="" required></b-form-datepicker>
                </b-form-group>
              </div>

              <div class="col-6 col-sm-5 mb-2">
                <b-form-group class="custom-f-group-2"
                :label='$t("projects.form.horario")'>
                  <b-form-select v-model="form.alterativeHour" size="lg" :options="hoursOptions"></b-form-select>
                </b-form-group>
              </div>

            </div>
          </div>

          <div class="col-lg-5 col-comments mb-2">
            <b-form-group class="custom-f-group-2"
            :label='$t("projects.form.comentarios")'>
              <b-form-textarea
                v-model="form.comments"
                placeholder=""
                required
              ></b-form-textarea>
            </b-form-group>
          </div>

          <div class="col-12">
            <vue-recaptcha class="box-recaptcha"
              sitekey="6LcaE4okAAAAAFYKGFfWc9wbF0IXRcRBzSzZmiyw"
              ref="recaptcha"
              @verify="handleSuccess"
              >
            </vue-recaptcha>
          </div>

          <div class="col-12 mt-1 text-center">
            <b-button type="submit" class="btn-s1 blue btn-bg">{{$t("projects.form.submit")}}</b-button>
          </div>
        </b-form>
      </div>

      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>
      </sweet-modal>
    </section>

  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  components: {
    CoolLightBox,
  },

  data() {
    return {
      banners: [
        { imageUrl: 'public/images/pages/projects/project-b-1.jpg' },
        { imageUrl: 'public/images/pages/projects/project-b-2.jpg' },
        { imageUrl: 'public/images/pages/projects/project-b-3.jpg' },
        { imageUrl: 'public/images/pages/projects/project-b-4.jpg' },
      ],

      banners2: [
        { imageUrl: 'public/images/pages/projects/banner-b1.jpg' },
        { imageUrl: 'public/images/pages/projects/banner-b2.jpg' },
      ],

      index: null,
      gallery: [
        'public/images/pages/projects/project-b-0.jpg',
        'public/images/pages/projects/project-b-2.jpg',
        'public/images/pages/projects/project-b-1.jpg',
        'public/images/pages/projects/project-b-3.jpg',
      ],

      hOptions: [
        { value: '', text: 'Please select an option', disabled: true },
        { value: 'Advertisement', text: 'Advertisement' },
        { value: 'Email', text: 'Email' },
        { value: 'Event', text: 'Event' },
        { value: 'Facebook', text: 'Facebook' },
        { value: 'Brochure EXPLORE', text: 'Brochure EXPLORE' },
        { value: 'Reference', text: 'Reference' },
        { value: 'For sale sign', text: 'For sale sign' },
        { value: 'Walking', text: 'Walking' },
        { value: 'Website', text: 'Website' },
      ],
      hOptionsEs: [
        { value: '', text: 'Por favor seleccione una opción', disabled: true },
        { value: 'Anuncio', text: 'Anuncio' },
        { value: 'Email', text: 'Email' },
        { value: 'Evento', text: 'Evento' },
        { value: 'Facebook', text: 'Facebook' },
        { value: 'Folleto EXPLORAR', text: 'Folleto EXPLORAR' },
        { value: 'Referencia', text: 'Referencia' },
        { value: 'Señal de venta', text: 'Señal de venta' },
        { value: 'Caminando', text: 'Caminando' },
        { value: 'Sitio web', text: 'Sitio web' },
      ],

      hoursOptions: [
        { value: null, text: '', disabled: true },
        { value: '8:00 am', text: '8:00 am' },
        { value: '9:00 am', text: '9:00 am' },
        { value: '10:00 am', text: '10:00 am' },
        { value: '11:00 am', text: '11:00 am' },
        { value: '12:00 pm', text: '12:00 pm' },
        { value: '1:00 pm', text: '1:00 pm' },
        { value: '2:00 pm', text: '2:00 pm' },
        { value: '3:00 pm', text: '3:00 pm' },
        { value: '4:00 pm', text: '4:00 pm' },
        { value: '5:00 pm', text: '5:00 pm' },
      ],

      // == Variables para datepicker ==
      minDate: null,
      dateFormOpts: { year: 'numeric', month: 'numeric', day: 'numeric' },
      // == ==

      form: {
        name: null,
        phone: null,
        email: null,
        hear: null,
        bestDate: null,
        bestHour: null,
        alterativeDate: null,
        alterativeHour: null,
        comments: null,
      },

      recaptchaVerified:false,

      modal:{
        msg:'',
        icon:'',
        block:false,
      },

      project:{
        lat:0,
        lng:0,
      },

      // == Carousels options ==
      bannersOption: {
        loop: true,
        speed:800,
        effect: 'fade',

        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
          clickable: true,
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
      },

      banners2Option: {
        loop: true,
        speed:800,
        effect: 'fade',

        autoplay: {
          delay: 6500,
          disableOnInteraction: false
        },
      },

      galleryOptions: {
        loop: false,
        slidesPerView: 3,
        spaceBetween: 30,
        clickable: true,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        breakpoints: {
          992: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 20,

            autoplay: {
              delay: 50000,
              disableOnInteraction: false
            },
          },
          1: {
            slidesPerView: 1,
            autoplay: {
              delay: 5000,
              disableOnInteraction: false
            },
          },
        }
      },
      showMap:false
      // == ==
    }
  },

  methods: {
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`)
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6
    },

    getProject() { // Obtener la info de la BD
      axios.get(tools.url("/api/projects/"+this.$route.params.id)).then((response)=>{
        this.project = response.data;
      });
    },

    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    onSubmit(evt) {
      evt.preventDefault();
      if(this.recaptchaVerified == true){

        this.modal.block = true;
        this.modal.icon = "";
        this.modal.msg = "Loading...";
        this.$refs.modal.open();

        var params = this.form;
        var apiURL = tools.url("/api/contact");

        axios.post( apiURL,params )
        .then( (response) => {
          // alert(response.data.response);
          this.makeToast('success', response.data.response, 'Message sent');
          // alert(response.data.response);

          for (let prop in this.form) {
            this.form[prop] = '';
          }
          this.modal.block = false;
          this.modal.icon = "success";
          this.modal.msg = "Information saved correctly";
        })
        .catch( (error) => {
          // console.log(error.response.data);
          let errors = error.response.data;

          for (let prop in errors) {
            // console.log(errors[prop]);
            this.makeToast('danger', errors[prop], '¡Error!');
          }
          this.modal.block = false;
          this.modal.icon = "error";
          this.modal.msg = "Error saving information";
        });
      }else{
        this.modal.block = false;
        this.modal.icon = "warning";
        this.modal.msg = "Complete the captcha";
        this.$refs.modal.open();
      }
    },

    handleSuccess(response) {
      this.recaptchaVerified = true;
    },
  },

  beforeMount(){
    const now = new Date();
    this.minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  },

  mounted(){
    if(this.$route.params.id){
        this.getProject();
    }
    var self = this;
    setTimeout(()=> {
      self.showMap = true;
      }, 1000);
  },
}
</script>
